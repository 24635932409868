import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited minh0" }
const _hoisted_2 = { class: "card flex1 minh0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_BillingTable = _resolveComponent("BillingTable")!
  const _component_BillingCustomerDialog = _resolveComponent("BillingCustomerDialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('billing_customers.title')
    }, {
      actions: _withCtx(() => [
        _createVNode(_component_router_link, { to: _ctx.routeName }, {
          default: _withCtx(() => [
            _createVNode(_component_Button, {
              icon: "pi pi-plus",
              label: _ctx.$t('billing_customers.new')
            }, null, 8, ["label"])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_BillingTable, {
        ref: "dataTable",
        filters: _ctx.filters,
        "onUpdate:filters": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters = $event)),
        isLoading: _ctx.isLoading||_ctx.exportInProgress,
        totalRecords: _ctx.totalRecords,
        reports: _ctx.reports,
        onUpdate: _ctx.onUpdate,
        onExport: _ctx.onExport,
        onEdit: _ctx.onEdit
      }, null, 8, ["filters", "isLoading", "totalRecords", "reports", "onUpdate", "onExport", "onEdit"]),
      _createVNode(_component_BillingCustomerDialog, {
        visible: _ctx.displayEditDialog,
        "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => (_ctx.displayEditDialog = $event)),
        editValue: _ctx.toEdit,
        onSaved: _ctx.onSaved
      }, null, 8, ["visible", "editValue", "onSaved"])
    ])
  ]))
}