
import { PageHeader } from "@/components";

import { PrimeVueEvent }        from '@/model/common/PrimeVueTypes';
import { reportsService }       from "@services/reports.service";
import { DataTableFilterMeta }  from "primevue/datatable";
import { defineComponent, ref } from 'vue';
import { BillingRoutesEnum }    from '../../router';

import { formerList } from "@/modules/billing/logic/FormerList";
import { UserType }   from "@/model/Entity";

import { BillingTable }      from "../../components"
import moment                from "moment";
import { useExport }         from "../../logic/export";
import BillingCustomerDialog from "@/modules/billing/components/billing-customer-dialog/BillingCustomerDialog.vue";
import { Report }            from "@/model/Report";
import { useMessage }        from "@plugins/Messages-plugin";

export default defineComponent( {
  components: {
    BillingCustomerDialog,
    PageHeader,
    BillingTable
  },

  beforeRouteLeave() {
    if (reportsService?.cancelPendingRequests) {
      reportsService?.cancelPendingRequests();
    }
  },

  setup() {
    const dataTable = ref();

    const routeName = {
      name: BillingRoutesEnum.BILLING_CUSTOMERS_FIND
    };

    const filters = ref<DataTableFilterMeta>({
      type: {
        value: [],
        matchMode: "in"
      },
      business_name: {
        value: null,
        matchMode: "contains"
      },
      period: {
        value: [
          moment().subtract(1, 'month').startOf('month').toDate(),
          moment().subtract(1, 'month').endOf('month').toDate(),
        ],
        matchMode: "contains"
      }
    });

    const {
      reports,
      totalRecords,
      isLoading,
      loadReports,
    } = formerList(UserType.CUSTOMER)

    const {
            exportInProgress,
            onExport
          } = useExport( UserType.CUSTOMER )

    function onUpdate( params: PrimeVueEvent ) {
      params?.originalEvent && delete params.originalEvent;

      loadReports( params );
    }


    const { successMessage } = useMessage();
    const displayEditDialog  = ref( false );
    const toEdit             = ref<Report>();

    function onEdit( data: Report ) {
      toEdit.value            = data;
      displayEditDialog.value = true;
    }

    function onSaved() {
      successMessage( 'Report aggiornato!' );
      loadReports( dataTable.value.$refs.table.createLazyLoadEvent() );
    }

    return {
      routeName,
      isLoading,

      filters,

      reports,
      totalRecords,

      onUpdate,

      exportInProgress,
      onExport,

      displayEditDialog,
      onEdit,
      toEdit,
      onSaved,

      dataTable
    }
  }
})
