
import { defineComponent } from 'vue';

import { PageHeader } from "@/components";

import Filters from "../../components/filters/Filters.vue";

import UnprocessedEntityTable 
  from "../../components/unprocessed-entity-table/UnprocessedEntityTable.vue";

import { UnprocessedEntities } from '../../logic/UnprocessedEntities';

import { UserType } from '@/model/Entity';

export default defineComponent({
  components: {
    PageHeader,
    Filters,
    UnprocessedEntityTable
  },
  setup() {
    return {
      ...UnprocessedEntities(UserType.CUSTOMER)
    }
  }
})
