
import { computed, defineComponent, onMounted, ref } from "vue";

import { PageHeader }                  from "@/components";
import { customersService }            from "@services/customers.service";
import { useMessage }                  from "@plugins/Messages-plugin";
import { reportsService }              from "@services/reports.service";
import { UnprocessedOrder }            from "@/model/Report";
import { PaginatedResponse }           from "@/model/PaginatedResponse";
import { PrimeVueEvent }               from "@/model/common/PrimeVueTypes";
import { BillingRoutesEnum }           from "../../router";
import { RouteLocationRaw, useRouter } from "vue-router";
import { zonesService }                from "@services/zones.service";
import { Zone }                        from "@/model/Zone";

import ChipFilters  from "../../components/chip-fitlers/ChipFilters.vue";
import OrderTable   from "../../components/order-table/OrderTable.vue";
import CurrencyText from "../../../../../nexor_vue_components/components/CurrencyText/CurrencyText.vue";


export default defineComponent( {
  props: {
    entityId: {
      type    : String,
      required: true
    },
    from    : {
      type    : String,
      required: true

    },
    to: {
      type: String,
      required: true

    },
    zoneId: {
      type: String,
      required: false
    }
  },

  components: {
    CurrencyText,
    PageHeader,
    ChipFilters,
    OrderTable
  },

  setup(p) {
    const isLoading = ref<boolean>(false);

    const {
      successMessage,
      errorMessage,
      confirmMessage,
    } = useMessage()

    const router = useRouter();

    const backRoute = computed(() => {
      return {
        name: BillingRoutesEnum.BILLING_CUSTOMERS_FIND,
        query: {
          date_from_at : p.from,
          date_to_at   : p.to,
          zone_id      : p.zoneId
        }
      } as RouteLocationRaw
    })

    //#region Page action
    async function onProcessClick() {
      if (total.value == null) {
        errorMessage("Inserire il totale");
        return;
      }

      const rx = await confirmMessage(
        `Generare report per il cliente ${customerName.value}?`,
        "Conferma elaborazione"
      )

      if (rx) {
        process()
      }
    }

    async function process() {
      try {
        isLoading.value = true

        await reportsService.processOrders({
          entity_id    : +p.entityId,
          zone_id      : p.zoneId ? +p.zoneId : null,

          date_from_at : p.from as string,
          date_to_at   : p.to   as string,

          total_amount : total.value,
          note         : note.value
        })

        router.replace(backRoute.value)
          .then(() => successMessage(
            `Ordini elaborati con successo per il cliente ${customerName.value}`
          ));
      } catch (error) {
        errorMessage("Generazione report non riuscita");
      } finally {
        isLoading.value = false
      }
    }
    //#endregion

    //#region Order table
    const response      = ref<PaginatedResponse<UnprocessedOrder>>(null);
    const orders        = computed( () => response.value?.data);
    const totalRecords  = computed( () => response.value?.total);

    function onPage(params: PrimeVueEvent) {
      params?.originalEvent && delete params.originalEvent;

      loadUnprocessedOrders(params);
    }

    async function loadUnprocessedOrders(e?: PrimeVueEvent) {
      try {
        isLoading.value = true;

        response.value = await reportsService.unprocessedOrders({
          entity_id    : +p.entityId,
          date_from_at : p.from as string,
          date_to_at   : p.to as string,
          zone_id      : +p.zoneId || null,

          page    : e?.page ?? 1,
          per_page: e?.rows ?? 10,
        });
      } catch (error) {
        errorMessage("Caricamento ordini non riuscito");
      } finally {
        isLoading.value = false;
      }
    }

    //#endregion

    // #region KPI
    const kpiLoading = ref<boolean>(false);
    const total  = ref(0)
    const note   = ref("")
    async function loadKpi() {
      try {
        kpiLoading.value = true;

        total.value = (await reportsService.kpi({
          entity_id    : +p.entityId,
          date_from_at : p.from as string,
          date_to_at   : p.to as string,
          zone_id      : +p.zoneId || null,
        })).total_sys_amount;

      } catch (error) {
        errorMessage("Caricamento ordini non riuscito");
      } finally {
        kpiLoading.value = false;
      }
    }
    //#endregion

    //#region Customer
    const customer = ref(null)
    async function loadCustomerDetails(customerId: number) {
      customer.value = await customersService.getById(customerId)
    }
    const customerName = computed(() => {
      return customer.value?.business_name
    })
    //#endregion

    //#region Zone
    const zone = ref<Zone>(null);
    const zoneName = computed(() => zone.value?.name);
    async function loadZone() {
      if (p.zoneId) {
        zone.value = await zonesService.getById( +p.zoneId );
      }
    }
    //#endregion

    onMounted(() => {
      loadUnprocessedOrders();
      loadKpi();
      loadCustomerDetails(+p.entityId);
      loadZone();
    })

    return {
      isLoading,
      kpiLoading,
      backRoute,

      total,
      note,

      orders,
      totalRecords,
      onPage,

      customerName,
      zoneName,

      onProcessClick
    }
  }
});
