import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "p-d-flex p-ai-center" }
const _hoisted_3 = { class: "p-fluid p-mt-3" }
const _hoisted_4 = { class: "p-d-flex p-jc-center p-mt-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_FloatLabel = _resolveComponent("FloatLabel")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Button = _resolveComponent("Button")!
  const _component_PDialog = _resolveComponent("PDialog")!

  return (_openBlock(), _createBlock(_component_PDialog, _mergeProps({
    draggable: true,
    keepInViewPort: true,
    minX: 0,
    minY: 0,
    modal: true,
    class: "p-fluid",
    header: "Dettaglio report"
  }, _ctx.$attrs, {
    onHide: _ctx.onHide,
    onShow: _ctx.onShow
  }), {
    default: _withCtx(() => [
      (_ctx.report)
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_FloatLabel, {
                class: "p-mr-3",
                label: "Totale"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_InputNumber, {
                    modelValue: _ctx.report.total_amount,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.report.total_amount = $event)),
                    disabled: _ctx.isLoading,
                    currency: "EUR",
                    locale: "it-IT",
                    mode: "currency"
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_3, [
              _createVNode(_component_FloatLabel, { label: "Note" }, {
                default: _withCtx(() => [
                  _createVNode(_component_Textarea, {
                    modelValue: _ctx.report.note,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.report.note = $event)),
                    disabled: _ctx.isLoading,
                    cols: "35",
                    placeholder: "Scrivi qui le note..."
                  }, null, 8, ["modelValue", "disabled"])
                ]),
                _: 1
              })
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_Button, {
                loading: _ctx.isLoading,
                class: "p-button-lg",
                icon: "pi pi-check-circle",
                label: "Aggiorna",
                onClick: _ctx.onSubmit
              }, null, 8, ["loading", "onClick"])
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16, ["onHide", "onShow"]))
}