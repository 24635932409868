
import { defineComponent, ref, toRef } from "vue";
import { Report }                      from "@/model/Report";
import { reportsService }              from "@services/reports.service";

export default defineComponent( {
  name : "BillingCustomerDialog",
  props: {
    editValue: Object
  },
  setup( props, { emit } ) {
    const editValue = toRef( props, "editValue" );
    const report    = ref<Report>();

    const isLoading = ref( false );

    function onShow() {
      report.value = editValue.value as Report;
    }

    function onHide() {
      report.value = null;
    }

    async function onSubmit() {
      try {
        isLoading.value = true;

        const response = await reportsService.updateReport(
            report.value.id,
            {
              total_amount: report.value.total_amount,
              note        : report.value.note
            }
        );

        emit( 'saved', response );
        emit( 'update:visible', false );
      } catch (e) {
        console.error( e );
      } finally {
        isLoading.value = false;
      }
    }

    return {
      isLoading,
      report,
      onShow,
      onHide,
      onSubmit
    };
  }
} )
