import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page--limited" }
const _hoisted_2 = { class: "card flex1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_Filters = _resolveComponent("Filters")!
  const _component_UnprocessedEntityTable = _resolveComponent("UnprocessedEntityTable")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_PageHeader, {
      title: _ctx.$t('billing_customers.find_title'),
      showSave: false,
      showDelete: false
    }, {
      left: _withCtx(() => [
        _createVNode(_component_router_link, { to: _ctx.backRoute }, {
          default: _withCtx(() => [
            _withDirectives(_createVNode(_component_Button, { icon: "pi pi-arrow-left" }, null, 512), [
              [
                _directive_tooltip,
                _ctx.$t('back'),
                void 0,
                { bottom: true }
              ]
            ])
          ]),
          _: 1
        }, 8, ["to"])
      ]),
      _: 1
    }, 8, ["title"]),
    _createVNode(_component_Filters, {
      modelValue: _ctx.filters,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.filters = $event)),
      onSubmit: _ctx.onFiltersSubmit
    }, null, 8, ["modelValue", "onSubmit"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_UnprocessedEntityTable, {
        value: _ctx.values,
        totalRecords: _ctx.totalRecords,
        isLoading: _ctx.isLoading,
        getRoute: _ctx.getRoute,
        onUpdate: _ctx.loadData
      }, null, 8, ["value", "totalRecords", "isLoading", "getRoute", "onUpdate"])
    ])
  ]))
}